import { COST_EVALUATOR_NAME, LATENCY_EVALUATOR_NAME, TOKENS_EVALUATOR_NAME } from "@/services/evaluations.service";
import {
  MonitoringEvaluator,
  MonitoringEvaluatorResponse,
  parseMonitoringEvaluatorResponse,
} from "@/services/files.service";
import { DashboardConfiguration } from "@/services/projects.service";
import { parseTimestampsInResponse } from "@/services/utils";
import { parseEnvironmentResponse } from "./environment";
import { BaseFileResponse, FileFromResponse } from "./file";
import { FileRequestIdentifiers } from "./log";
import { PromptKernelRequest } from "./prompt";
import { EvaluatorAggregate } from "./version";

/// Possible return types an evaluator can have.
const EVALUATOR_RETURN_TYPES = ["boolean", "number", "select", "multi_select", "text"] as const;
export type EvaluatorReturnType = (typeof EVALUATOR_RETURN_TYPES)[number];

// Convert a return type to a capitalized string for display.
export const returnTypeToString = (returnType: EvaluatorReturnType): string => {
  switch (returnType) {
    case "boolean":
      return "Boolean";
    case "number":
      return "Number";
    case "select":
      return "Select";
    case "multi_select":
      return "Multi-select";
    case "text":
      return "Text";
  }
};

export const RETURN_TYPES_WITH_OPTIONS: EvaluatorReturnType[] = ["select", "multi_select"];

export type EvaluatorArgumentsType = "target_free" | "target_required";

export type EvaluatorType = "python" | "llm" | "human" | "external";

interface BaseEvaluatorKernelParams {
  return_type: EvaluatorReturnType;
  arguments_type: EvaluatorArgumentsType;
  attributes?: Record<string, any> | null;
  options?: EvaluatorJudgmentOption[] | null;
  number_limits?: EvaluatorJudgmentNumberLimit | null;
  number_valence?: Valence | null;
}

export interface LLMEvaluatorRequest extends BaseEvaluatorKernelParams {
  evaluator_type: "llm";
  prompt: PromptKernelRequest;
}

export interface CodeEvaluatorRequest extends BaseEvaluatorKernelParams {
  evaluator_type: "python";
  code: string | null;
}

export type Valence = "positive" | "negative" | "neutral";
export const VALENCES: Valence[] = ["positive", "negative", "neutral"];

export const valenceToShade = (valence: Valence): "green" | "red" | "gray" => {
  switch (valence) {
    case "positive":
      return "green";
    case "negative":
      return "red";
    case "neutral":
      return "gray";
  }
};

export interface EvaluatorJudgmentOption {
  name: string;
  valence: Valence;
}

export const getReturnTypesForEvaluatorType = (evaluatorType: EvaluatorType): EvaluatorReturnType[] => {
  switch (evaluatorType) {
    case "llm":
    case "python":
    case "external":
      return ["boolean", "number"];
    case "human":
      return ["select", "multi_select", "text", "number"];
    default:
      const _exhaustiveCheck: never = evaluatorType;
      throw new Error(`Unknown evaluator type: ${evaluatorType}`);
  }
};

interface EvaluatorJudgmentNumberLimit {
  min: number | null;
  max: number | null;
  step: number | null;
}

export interface HumanEvaluatorRequest extends BaseEvaluatorKernelParams {
  evaluator_type: "human";
  return_type: "select" | "multi_select" | "text" | "number";
  instructions?: string | null;
}

export interface ExternalEvaluatorSpec extends BaseEvaluatorKernelParams {
  evaluator_type: "external";
}

export type EvaluatorSpec = LLMEvaluatorRequest | CodeEvaluatorRequest | HumanEvaluatorRequest | ExternalEvaluatorSpec;

export interface EvaluatorRequest extends FileRequestIdentifiers {
  commit_message: string | null;
  spec: EvaluatorSpec;
}

export interface UpdateEvaluatorRequest {
  path?: string | null;
  name?: string | null;
}

export interface EvaluatorResponse
  extends Omit<EvaluatorRequest, "id" | "path" | "directory_id">,
    BaseFileResponse<"evaluator"> {
  default_evaluator: boolean;

  version_logs_count: number;
  total_logs_count: number;
  evaluators: MonitoringEvaluatorResponse[] | null;

  inputs: { name: string }[];
  team_id: string;
  dashboard_configuration?: DashboardConfiguration;
  evaluator_aggregates: EvaluatorAggregate[] | null;

  attributes?: Record<string, any> | null;
}

export interface Evaluator extends Omit<FileFromResponse<EvaluatorResponse>, "evaluators"> {
  evaluators: MonitoringEvaluator[] | null;
}

// Helper types
export type HumanEvaluator = Evaluator & { spec: HumanEvaluatorRequest };
export type LLMEvaluator = Evaluator & { spec: LLMEvaluatorRequest };
export type CodeEvaluator = Evaluator & { spec: CodeEvaluatorRequest };
export type ExternalEvaluator = Evaluator & { spec: ExternalEvaluatorSpec };

export const parseEvaluatorResponse = (response: EvaluatorResponse): Evaluator => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
    evaluators: response.evaluators
      ? response.evaluators.map((evaluator) => parseMonitoringEvaluatorResponse(evaluator))
      : null,
    spec: {
      ...response.spec,
      options: "options" in response.spec && response.spec.options ? sortOptions(response.spec.options) : null,
    },
  };
};

/**
 * Sort options by valence:
 * - Negative valence options come first
 * - Neutral valence options come second
 * - Positive valence options come last
 * If the evaluator spec doesn't have options, return an empty array
 */
export const sortOptions = (options: EvaluatorJudgmentOption[]): EvaluatorJudgmentOption[] => {
  return options.toSorted((a, b) => {
    const aValence = a.valence === "negative" ? -1 : a.valence === "neutral" ? 0 : 1;
    const bValence = b.valence === "negative" ? -1 : b.valence === "neutral" ? 0 : 1;
    return aValence - bValence;
  });
};

export const higherIsBetter = (evaluator: Evaluator): boolean => {
  // this is not sophisticated list, please update it if you need to
  // Ideally this data should be stored in the evaluator itself
  return !(
    evaluator.name === COST_EVALUATOR_NAME ||
    evaluator.name === TOKENS_EVALUATOR_NAME ||
    evaluator.name === LATENCY_EVALUATOR_NAME
  );
};

export const getIsLLMEvaluator = (evaluator: Evaluator): evaluator is LLMEvaluator => {
  return evaluator.spec.evaluator_type === "llm";
};
export const getIsHumanEvaluator = (evaluator: Evaluator): evaluator is HumanEvaluator => {
  return evaluator.spec.evaluator_type === "human";
};
export const getIsCodeEvaluator = (evaluator: Evaluator): evaluator is CodeEvaluator => {
  return evaluator.spec.evaluator_type === "python";
};
export const getIsExternalEvaluator = (evaluator: Evaluator): evaluator is ExternalEvaluator => {
  return evaluator.spec.evaluator_type === "external";
};
