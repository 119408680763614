import { ChatMessageData, ModelEndpoint, ModelProvider } from "@/services/playground.service";
import { BaseFileResponse, FileFromResponse } from "./file";
import { ToolFunction } from "./tool";
import { parseTimestampsInResponse } from "@/services/utils";
import { DashboardConfiguration } from "@/services/projects.service";
import {
  MonitoringEvaluator,
  MonitoringEvaluatorResponse,
  parseMonitoringEvaluatorResponse,
} from "@/services/files.service";
import { parseEnvironmentResponse } from "./environment";
import { EvaluatorAggregate } from "./version";
import { FileRequestIdentifiers } from "./log";
import _ from "lodash";

type PromptTemplate = string;
type ChatTemplate = ChatMessageData[];

export const JSON_OBJECT_RESPONSE_FORMAT = { type: "json_object" } as const;
export const JSON_SCHEMA_RESPONSE_FORMAT = { type: "json_schema", json_schema: {} } as const;
export type ResponseFormat = typeof JSON_OBJECT_RESPONSE_FORMAT | typeof JSON_SCHEMA_RESPONSE_FORMAT;
export type TemplateLanguage = "default" | "jinja";

export interface PromptKernelRequest {
  model: string;
  endpoint: ModelEndpoint;
  template: PromptTemplate | ChatTemplate | null;
  provider: ModelProvider;
  template_language?: TemplateLanguage | null;

  max_tokens?: number | null;
  temperature?: number | null;
  top_p?: number | null;

  stop?: string[] | string | null;

  frequency_penalty?: number | null;
  presence_penalty?: number | null;
  other?: Record<string, any> | null;
  seed?: number | null;
  response_format?: ResponseFormat | null;
  reasoning_effort?: "high" | "medium" | "low" | null;
  tools?: ToolFunction[] | null;
  linked_tools?: string[] | null;
  attributes?: Record<string, any> | null;
}

export interface PromptRequest extends PromptKernelRequest, FileRequestIdentifiers {
  commit_message?: string | null;
}

export interface UpdatePromptRequest {
  path?: string | null;
  name?: string | null;
}

interface LinkedToolResponse extends ToolFunction {
  id: string;
  version_id: string;
}

export interface PromptResponse
  extends Omit<PromptRequest, "id" | "path" | "directory_id" | "linked_tools">,
    BaseFileResponse<"prompt"> {
  linked_tools: LinkedToolResponse[];
  version_logs_count: number;
  total_logs_count: number;
  evaluators: MonitoringEvaluatorResponse[] | null;

  inputs: { name: string }[];
  team_id: string;
  dashboard_configuration?: DashboardConfiguration;
  evaluator_aggregates: EvaluatorAggregate[] | null;
}

export interface Prompt extends Omit<FileFromResponse<PromptResponse>, "evaluators"> {
  evaluators: MonitoringEvaluator[] | null;
}

export const parsePromptResponse = (response: PromptResponse): Prompt => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
    evaluators: response.evaluators
      ? response.evaluators.map((evaluator) => parseMonitoringEvaluatorResponse(evaluator))
      : null,
  };
};

/** Helper to check equality of Prompt kernels.
 *
 * Our backend can add in null attributes that do not actually change the Prompt.
 * E.g. `prompt.template[0].name` would be returned as `null` in our backend if unset.
 */
export const promptIsEqual = (a: PromptKernelRequest, b: PromptKernelRequest): boolean => {
  const cleanPrompt = (prompt: PromptKernelRequest) => {
    let cleanedPrompt = _.omitBy(prompt, _.isEmpty);
    if (cleanedPrompt.template && Array.isArray(cleanedPrompt.template)) {
      cleanedPrompt.template = cleanedPrompt.template.map((chatMessage) => _.omitBy(chatMessage, _.isEmpty));
    }
    return cleanedPrompt;
  };
  const cleanedA = cleanPrompt(a);
  const cleanedB = cleanPrompt(b);
  return _.isEqual(cleanedA, cleanedB);
};
